<template>
    <footer class="f-footer">
            <div class="row align-items-center">
                <div class="col align-center-sm align-center">
                    <a href="https://www.oho.ai/" target="_blank" rel="nofollow">©2022 OHO Blockchain Project</a>
                </div>
            </div>
    </footer>
</template>

<script>
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks.vue';

export default {
    components: {
        SocialMediaLinks,
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
