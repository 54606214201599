<template>
    <ul class="f-social-media-links no-markers">
        <li>
            <a href="#" target="_blank" rel="nofollow" title="OHO on Discord">
                <icon data="@/assets/svg/social-media/discord.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://twitter.com/AnbSwap" target="_blank" rel="nofollow" title="OHO on Twitter">
                <icon data="@/assets/svg/social-media/twitter.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" rel="nofollow" title="OHO on Reddit">
                <icon data="@/assets/svg/social-media/reddit.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" rel="nofollow" title="OHO on Github">
                <icon data="@/assets/svg/social-media/github.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" rel="nofollow" title="OHO on Medium">
                <icon data="@/assets/svg/social-media/medium.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a
                href="https://www.linkedin.com/company/anb-swap/"
                target="_blank"
                rel="nofollow"
                title="OHO on Linkedin"
                class="linkedin"
            >
                <icon data="@/assets/svg/social-media/linkedin.svg" width="24" height="24"></icon>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'SocialMediaLinks',
};
</script>

<style lang="scss">
@import 'style';
</style>
