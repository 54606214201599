import { render, staticRenderFns } from "./LedgerConfirmationContent.vue?vue&type=template&id=2ba692f1&"
import script from "./LedgerConfirmationContent.vue?vue&type=script&lang=js&"
export * from "./LedgerConfirmationContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports